.mvv{
    &__title{
        margin-top: 80px;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        @include mq(sm, max){
            margin-top: 40px;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
        }
    }
    &__desc{
        margin-top: 40px;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        @include mq(sm, max){
            margin-top: 20px;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
        }
    }
    &__theme{
        margin-top: 16px;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        @include mq(sm, max){
            margin-top: 12px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
        }
    }
    &__text{
        margin-top: 40px;
        font-size: 24px;
        font-weight: 400;
        line-height: 42px;
        @include mq(sm, max){
            margin-top: 20px;
            font-size: 20px;
            line-height: 35px;
        }
    }
}