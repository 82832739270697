.loading{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $color-white;
    z-index: 10000;
    &__items{
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(15px * 4);
        height: 15px;
        transform: translate(-50%, -50%);
        .rhombus {
            width: 20px;
            height: 19px;
            left: calc(20px * 4);
            position: absolute;
            margin: 0 auto;
            transform: translateY(0) scale(0);
            animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
            &:nth-child(1) {
                animation-delay: calc(2500ms * 1 / -1.5);
            }
            &:nth-child(2) {
                animation-delay: calc(2500ms * 2 / -1.5);
            }
            &:nth-child(3) {
                animation-delay: calc(2500ms * 3 / -1.5);
            }
        }
    }

}
.loading__items, .loading__items * {
    box-sizing: border-box;
}

@keyframes looping-rhombuses-spinner-animation {
    0% {
        transform: translateX(0) scale(0);
    }
    50% {
        transform: translateX(-233%) scale(1);
    }
    100% {
        transform: translateX(-466%) scale(0);
    }
}


@media all and (-ms-high-contrast: none) {
    .loading{
        display: none;
    }
}