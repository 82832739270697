.service{
    &__content{
        padding: 0 7.6%;
        @include mq(1089px, max){
            padding: 0 20px;
        }
        @include mq(sm, max){
            padding: 0 7.2%;
        }
    }
    &list{
        @extend %flexcenter;
        padding: 40px 0 0;
        @include mq(md, max){
            padding: 40px 0;
        }
        @include mq(sm, max){
            display: block;
            padding: 20px 0 0;
        }
        &__block{
            width: 50%;
            min-height: 365px;
            box-shadow: 0 0 10px 0 rgba(0, 91, 95, 0.15);
            background-color: rgba(255, 255, 255, 0.95);
            padding: 24px 40px 40px;
            @include mq(1350px, max){
                height: 400px;
            }
            @include mq(md, max){
                height: auto;
            }
            @include mq(sm, max){
                width: 100%;
                min-height: auto;
                padding: 16px 16px 20px;
            }
            &:first-child{
                margin: 0 1.5% 0 0;
                @include mq(sm, max){
                    margin: 0 auto 20px;
                }
            }
        }

        &__title{
            position: relative;
            color: $color-main;
            font-size: _rem(20px,md);
            font-weight: bold;
            text-align: center;
            border-bottom: 1px solid #888888;
            padding: 0 0 14px;
            @include mq(sm, max){
                font-size: _rem(18px,sm);
                padding: 0 0 10px;
            }
            @include mq(320px, max){
                font-size: _rem(16px,sm);
            }
            &:before,&:after{
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                left: 50%;
                border-style: solid;
                display: block;
                transform: translateX(-50%);
            }
            &:before{
                bottom: -11px;
                border-width: 12px 11.5px 0 11.5px;
                border-color: #ffffff transparent transparent transparent;
                z-index: 1;
                @include mq(sm, max){
                    bottom: -8px;
                    border-width: 9px 8.5px 0 8.5px;
                }
            }
            &:after{
                bottom: -13px;
                border-width: 13px 12px 0 12px;
                border-color: #888888 transparent transparent transparent;
                z-index: 0;
                @include mq(sm, max){
                    bottom: -10px;
                    border-width: 10px 9px 0 9px;
                }
            }
        }

        &__content{
            font-size: _rem(18px,md);
            font-family: $font-sanserif;
            text-align: left;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 30px 0 0;
            @include mq(963px, max){
                font-size: _rem(14px,md);
            }
            @include mq(sm, max){
                font-size: _rem(16px,sm);
                display: block;
                padding: 20px 0 0;
            }
            @include mq(320px, max){
                font-size: _rem(13px,sm);
            }
            &:first-child{
                justify-content: space-between;
            }
            &__block{
                &--02{
                    margin: 0 0 0 9%;
                    @include mq(1412px, max){
                        margin: 0 0 0 4%;
                    }
                    @include mq(sm, max){
                        margin: 14px 0 0;
                    }
                }
            }
            &__item{
                line-height: 1.5;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 0 24px;
                @include mq(sm, max){
                    padding: 0 5px 14px;
                }
                &:last-child{
                    padding: 0;
                    @include mq(sm, max){
                        padding: 0 5px;
                    }
                }
                &::before{
                    content: '';
                    width: 6px;
                    height: 6px;
                    display: block;
                    background-color: $color-gray-border;
                    margin: 0 10px 0 0;
                    flex-basis: 6px;
                    flex-shrink: 0;
                }
            }
        }
    }
}