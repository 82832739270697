.section{
    &#philosophy{
        .section__content{
            margin: 88px auto 120px;
            @include mq(sm, max){
                margin: 40px auto 80px;
            }
        }
    }
    &__content{
        margin: 100px auto 160px;
        @include mq(sm, max){
            margin: 40px auto 80px;
        }
        &.bg--mvv{
            margin: 100px auto;
            @include mq(sm, max){
                margin: 160px auto 100px;
            }
        }
        &.bg--service{
            margin: 100px auto;
            @include mq(sm, max){
                margin: 40px auto;
            }
        }
        &.bg--contact{
            margin: 100px auto 0;
            @include mq(sm, max){
                margin: 40px auto 0;
            }
        }
    }
    &__inner{
        padding: 0 7.6%;
        @include mq(786px, max){
            padding: 0 6%;
        }
        @include mq(sm, max){
            padding: 0 7.2%;
        }
        .section__title{
            max-width: 1440px;
            margin: auto;
        }
    }
    &__desc{
        &__block{
            &--center{
                text-align: center;
                @include mq(sm, max){
                    text-align: left;
                }
            }
            &--right{
                text-align: right;
                @include mq(sm, max){
                    text-align: left;
                }
            }
        }
        font-size: _rem(20px,md);
        line-height: 1.75;
        padding: 24px 0 16px;
        @include mq(sm, max){
            font-size: _rem(18px,sm);
            padding: 20px 0 10px;
        }
        @include mq(320px, max){
            font-size: _rem(16px,sm);
        }
        &__banner-pc {
            padding-top: 60px;
            @include mq(sm, max){
                display: none;
            }
        }
        &__banner-sp {
            padding-top: 30px;
            display: none;
            @include mq(sm, max){
                display: block;
            }
        }
    }
    &__sub-desc{
        line-height: 2;
        @include mq(sm, max){
            font-size: _rem(16px,sm);
            line-height: 1.75;
        }
        @include mq(320px, max){
            font-size: _rem(14px,sm);
        }
    }
    &__note{
        @include mq(sm, max){
            font-size: _rem(14px,sm);
            line-height: 1.5;
        }
    }
}

.bg{
    position: relative;
    &:after{
        position: absolute;
        content: '';
        top: 60px;
        width: calc(100% - 2.8%);
        height: 100%;
        display: block;
        background-color: $color-gray-bg;
        z-index: -1;
        @include mq(sm, max){
            top: 40px;
        }
    }
    &--left{
        @extend .bg;
        &:after{
            left: 0;
        }
    }
    &--right{
        @extend .bg;
        &:after{
            right: 0;
        }
    }
    // &--contact{
    //     @extend .bg;
    //     &:after{
    //         width: 100%;
    //         right: 0;
    //         left: 0;
    //     }
    // }
    &--service{
        position: relative;
        padding:  0 0 60px;
        @include mq(sm, max){
            padding:  0 0 40px;
        }
        &:after{
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 257px;
            display: block;
            // background: url($dir + '/top/service_bg_pc.jpg') no-repeat center;
            background-size: 100%;
            z-index: -1;
            @include mq(sm, max){
                height: 390px;
                // background: url($dir + '/top/service_bg_sp@2x.jpg') no-repeat center;
                background-size: 100%;
            }
        }
    }
}