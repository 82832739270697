%arrow-hover{
    path{
        opacity: 0;
        transition: $duration;
    }
    svg{
        @include mq(md, max){
            display: none;
        }
    }
    // a{
    //     &:hover{
    //         path{
    //             opacity: 1;
    //             fill: $color-cyan;
    //         }
    //     }
    // }
}


%flexcenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
%flexstart{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}