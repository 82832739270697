.greeting{
    &__content{
        position: relative;
        max-width: 1440px;
        margin: auto;
        padding: 40px 0 0;
        @include mq(sm, max){
            padding: 20px 0 0;
        }
    }
    &__thumb{
        position: absolute;
        top: 40px;
        left: 0;
        @include mq(md, max){
            width: 80%;
        }
        @include mq(sm, max){
            position: static;
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
    &__text{
        line-height: 2;
        p{
            padding: 0 0 24px;
            @include mq(sm, max){
                font-size: _rem(16px,sm);
                line-height: 1.8;
            }
            @include mq(320px, max){
                font-size: _rem(14px,sm);
            }
        }
        &__wrapper{
            position: relative;
            width: 48.6%;
            background-color: $color-white;
            margin: 60px 0 0 auto;
            padding: 40px;
            @include mq(md, max){
                width: 60%;
            }
            @include mq(sm, max){
                width: 100%;
                margin: 20px auto 0;
                padding: 24px 16px;
            }
        }
    }
    &__date{
        @include mq(sm, max){
            font-size: _rem(14px,sm);
        }
    }
    &__sign{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 16px 0 0;
        p{
            margin: 0 16px 2px 0;
            @include mq(sm, max){
                font-size: _rem(14px,sm);
            }
        }
        img{
            @include mq(md, max){
                width: 120px;
            }
        }
        &__wrapper{
            text-align: right;
        }
    }
}