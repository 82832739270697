@charset "UTF-8";
.btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
  position: relative;
  color: #ED406F;
  font-size: 1rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: center;
  width: 300px;
  border: 1px solid #ED406F;
  padding: 19px 0 20px;
}

@media (max-width: 768px) {
  .btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
    display: block;
    margin: auto;
  }
}

@media (max-width: 668px) {
  .btn, .btn--normal, .btn--submit, .btn--send, .btn--back {
    font-size: 1.23077rem;
    width: 100%;
  }
}

.btn svg, .btn--normal svg, .btn--submit svg, .btn--send svg, .btn--back svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
}

.btn svg rect, .btn--normal svg rect, .btn--submit svg rect, .btn--send svg rect, .btn--back svg rect {
  transition: 0.3s ease-in-out;
}

.btn svg polyline, .btn--normal svg polyline, .btn--submit svg polyline, .btn--send svg polyline, .btn--back svg polyline {
  transition: 0.3s ease-in-out;
}

.btn:hover, .btn--normal:hover, .btn--submit:hover, .btn--send:hover, .btn--back:hover {
  color: #ffffff;
  background-color: #ED406F;
}

.btn:hover svg rect, .btn--normal:hover svg rect, .btn--submit:hover svg rect, .btn--send:hover svg rect, .btn--back:hover svg rect {
  fill: #ffffff;
}

.btn:hover svg polyline, .btn--normal:hover svg polyline, .btn--submit:hover svg polyline, .btn--send:hover svg polyline, .btn--back:hover svg polyline {
  stroke: #ffffff;
}

.btn--normal svg {
  right: 10px;
}

.btn--submit {
  position: relative;
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow.svg") no-repeat center right 10px !important;
  background-size: 32px !important;
  padding: 16px 0 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn--submit:hover {
  background: url("/assets/img/common/icon-arrow-wh.svg") no-repeat center right 10px #ED406F !important;
}

.btn--send {
  color: #ffffff;
  position: relative;
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow-wh.svg") no-repeat center right 10px #ED406F !important;
  background-size: 32px !important;
  margin: 0 0 0 40px;
  padding: 16px 0 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn--send:hover {
  border: 1px solid #ED406F !important;
  background: url("/assets/img/common/icon-arrow.svg") no-repeat center right 10px #ffffff !important;
  background-size: 32px !important;
  color: #ED406F;
}

@media (max-width: 768px) {
  .btn--send {
    margin: 32px auto 0;
  }
}

.btn--back svg {
  left: 10px;
  transform: rotate(180deg) translateY(50%);
}

.section__title, .section__title--center, .section__title--right, .section__title--left {
  position: relative;
}

.section__title br, .section__title--center br, .section__title--right br, .section__title--left br {
  display: none;
}

@media (max-width: 668px) {
  .section__title br, .section__title--center br, .section__title--right br, .section__title--left br {
    display: block;
  }
}

.section__title--ja {
  color: #222222;
  font-size: 3.25rem;
  display: block;
  padding: 24px 0 16px;
  letter-spacing: 2.6px;
}

@media (max-width: 668px) {
  .section__title--ja {
    font-size: 2.92308rem;
    line-height: 1.2;
    padding: 16px 0 10px;
  }
}

.section__title--en {
  color: #001516;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.125rem;
  font-weight: lighter;
  letter-spacing: 1.06px;
  display: block;
}

@media (max-width: 668px) {
  .section__title--en {
    font-size: 1.07692rem;
  }
}

.section__title--center {
  text-align: center;
}

.section__title--right {
  text-align: right;
}

.section__title--left {
  text-align: left;
}

.servicelist, .contact__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 10000;
}

.loading__items {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(15px * 4);
  height: 15px;
  transform: translate(-50%, -50%);
}

.loading__items .rhombus {
  width: 20px;
  height: 19px;
  left: calc(20px * 4);
  position: absolute;
  margin: 0 auto;
  transform: translateY(0) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.loading__items .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.loading__items .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.loading__items .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

.loading__items, .loading__items * {
  box-sizing: border-box;
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) scale(0);
  }
  50% {
    transform: translateX(-233%) scale(1);
  }
  100% {
    transform: translateX(-466%) scale(0);
  }
}

@media all and (-ms-high-contrast: none) {
  .loading {
    display: none;
  }
}

.section#philosophy .section__content {
  margin: 88px auto 120px;
}

@media (max-width: 668px) {
  .section#philosophy .section__content {
    margin: 40px auto 80px;
  }
}

.section__content {
  margin: 100px auto 160px;
}

@media (max-width: 668px) {
  .section__content {
    margin: 40px auto 80px;
  }
}

.section__content.bg--mvv {
  margin: 100px auto;
}

@media (max-width: 668px) {
  .section__content.bg--mvv {
    margin: 160px auto 100px;
  }
}

.section__content.bg--service {
  margin: 100px auto;
}

@media (max-width: 668px) {
  .section__content.bg--service {
    margin: 40px auto;
  }
}

.section__content.bg--contact {
  margin: 100px auto 0;
}

@media (max-width: 668px) {
  .section__content.bg--contact {
    margin: 40px auto 0;
  }
}

.section__inner {
  padding: 0 7.6%;
}

@media (max-width: 786px) {
  .section__inner {
    padding: 0 6%;
  }
}

@media (max-width: 668px) {
  .section__inner {
    padding: 0 7.2%;
  }
}

.section__inner .section__title, .section__inner .section__title--center, .section__inner .section__title--right, .section__inner .section__title--left {
  max-width: 1440px;
  margin: auto;
}

.section__desc {
  font-size: 1.25rem;
  line-height: 1.75;
  padding: 24px 0 16px;
}

.section__desc__block--center {
  text-align: center;
}

@media (max-width: 668px) {
  .section__desc__block--center {
    text-align: left;
  }
}

.section__desc__block--right {
  text-align: right;
}

@media (max-width: 668px) {
  .section__desc__block--right {
    text-align: left;
  }
}

@media (max-width: 668px) {
  .section__desc {
    font-size: 1.38462rem;
    padding: 20px 0 10px;
  }
}

@media (max-width: 320px) {
  .section__desc {
    font-size: 1.23077rem;
  }
}

.section__desc__banner-pc {
  padding-top: 60px;
}

@media (max-width: 668px) {
  .section__desc__banner-pc {
    display: none;
  }
}

.section__desc__banner-sp {
  padding-top: 30px;
  display: none;
}

@media (max-width: 668px) {
  .section__desc__banner-sp {
    display: block;
  }
}

.section__sub-desc {
  line-height: 2;
}

@media (max-width: 668px) {
  .section__sub-desc {
    font-size: 1.23077rem;
    line-height: 1.75;
  }
}

@media (max-width: 320px) {
  .section__sub-desc {
    font-size: 1.07692rem;
  }
}

@media (max-width: 668px) {
  .section__note {
    font-size: 1.07692rem;
    line-height: 1.5;
  }
}

.bg, .bg--left, .bg--right {
  position: relative;
}

.bg:after, .bg--left:after, .bg--right:after {
  position: absolute;
  content: '';
  top: 60px;
  width: calc(100% - 2.8%);
  height: 100%;
  display: block;
  background-color: #f5f5f5;
  z-index: -1;
}

@media (max-width: 668px) {
  .bg:after, .bg--left:after, .bg--right:after {
    top: 40px;
  }
}

.bg--left:after {
  left: 0;
}

.bg--right:after {
  right: 0;
}

.bg--service {
  position: relative;
  padding: 0 0 60px;
}

@media (max-width: 668px) {
  .bg--service {
    padding: 0 0 40px;
  }
}

.bg--service:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 257px;
  display: block;
  background-size: 100%;
  z-index: -1;
}

@media (max-width: 668px) {
  .bg--service:after {
    height: 390px;
    background-size: 100%;
  }
}

.hero {
  position: relative;
  width: 100%;
}

@media (max-width: 668px) {
  .hero {
    height: calc(100vh - 50px);
    margin: 25px auto 0;
    overflow: hidden;
  }
}

.hero__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media (max-width: 668px) {
  .hero__logo {
    width: 280px;
    top: 45%;
  }
}

.slider__slide--01 {
  width: 100% !important;
  height: 100vh !important;
  background-size: cover;
}

@media (max-width: 668px) {
  .slider__slide--01 {
    height: calc(100vh - 50px) !important;
    background-image: url("/assets/img/top/hero_01_sp.jpg");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    overflow: hidden;
  }
}

.slider__slide--02 {
  width: 100% !important;
  height: 100vh !important;
  background-size: cover;
}

@media (max-width: 668px) {
  .slider__slide--02 {
    height: calc(100vh - 50px) !important;
    background-image: url("/assets/img/top/hero_02_sp.jpg");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    overflow: hidden;
  }
}

.slider__slide--03 {
  width: 100% !important;
  height: 100vh !important;
  background-size: cover;
}

@media (max-width: 668px) {
  .slider__slide--03 {
    height: calc(100vh - 50px) !important;
    background-image: url("/assets/img/top/hero_03_sp.jpg");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    overflow: hidden;
  }
}

.icon--print {
  position: fixed;
  color: #ffffff;
  height: 118px;
  top: 50%;
  right: 0;
  display: block;
  background-color: #666666;
  padding: 20px 16px;
  z-index: 10;
  transition: 0.3s ease-in-out;
}

.icon--print span {
  letter-spacing: 1.06px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  display: block;
  margin: 10px auto 0;
}

.icon--print:hover {
  background-color: #ED406F;
}

@media (max-width: 668px) {
  .icon--print {
    display: none;
  }
}

.philosophylist {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 66px 7.6% 0;
}

@media (max-width: 768px) {
  .philosophylist {
    padding: 66px 4% 0;
  }
}

@media (max-width: 668px) {
  .philosophylist {
    display: block;
    padding: 20px 7.2% 0;
  }
}

.philosophylist__block {
  max-width: 380px;
}

@media (max-width: 668px) {
  .philosophylist__block {
    max-width: 100%;
    margin: 0 auto 16px;
  }
}

.philosophylist__block:nth-child(2) {
  margin: 0 2.8%;
}

@media (max-width: 668px) {
  .philosophylist__block:nth-child(2) {
    margin: 0 auto 16px;
  }
}

@media (max-width: 668px) {
  .philosophylist__block:last-child {
    margin: 0 auto;
  }
}

.philosophylist__thumb img {
  width: 100%;
}

.philosophylist__num {
  position: relative;
  color: #ED406F;
  font-size: 3.75rem;
  font-style: italic;
  margin: 0 0 -20px;
  z-index: 1;
}

@media (max-width: 668px) {
  .philosophylist__num {
    font-size: 3.07692rem;
    margin: 0 0 -10px;
  }
}

.philosophylist__title {
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
  padding: 20px 0 0;
}

@media (max-width: 668px) {
  .philosophylist__title {
    font-size: 1.38462rem;
    padding: 10px 0 0;
  }
}

@media (max-width: 320px) {
  .philosophylist__title {
    font-size: 1.23077rem;
  }
}

.greeting__content {
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding: 40px 0 0;
}

@media (max-width: 668px) {
  .greeting__content {
    padding: 20px 0 0;
  }
}

.greeting__thumb {
  position: absolute;
  top: 40px;
  left: 0;
}

@media (max-width: 768px) {
  .greeting__thumb {
    width: 80%;
  }
}

@media (max-width: 668px) {
  .greeting__thumb {
    position: static;
    width: 100%;
  }
}

.greeting__thumb img {
  width: 100%;
}

.greeting__text {
  line-height: 2;
}

.greeting__text p {
  padding: 0 0 24px;
}

@media (max-width: 668px) {
  .greeting__text p {
    font-size: 1.23077rem;
    line-height: 1.8;
  }
}

@media (max-width: 320px) {
  .greeting__text p {
    font-size: 1.07692rem;
  }
}

.greeting__text__wrapper {
  position: relative;
  width: 48.6%;
  background-color: #ffffff;
  margin: 60px 0 0 auto;
  padding: 40px;
}

@media (max-width: 768px) {
  .greeting__text__wrapper {
    width: 60%;
  }
}

@media (max-width: 668px) {
  .greeting__text__wrapper {
    width: 100%;
    margin: 20px auto 0;
    padding: 24px 16px;
  }
}

@media (max-width: 668px) {
  .greeting__date {
    font-size: 1.07692rem;
  }
}

.greeting__sign {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px 0 0;
}

.greeting__sign p {
  margin: 0 16px 2px 0;
}

@media (max-width: 668px) {
  .greeting__sign p {
    font-size: 1.07692rem;
  }
}

@media (max-width: 768px) {
  .greeting__sign img {
    width: 120px;
  }
}

.greeting__sign__wrapper {
  text-align: right;
}

.mvv__title {
  margin-top: 80px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

@media (max-width: 668px) {
  .mvv__title {
    margin-top: 40px;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }
}

.mvv__desc {
  margin-top: 40px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

@media (max-width: 668px) {
  .mvv__desc {
    margin-top: 20px;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }
}

.mvv__theme {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

@media (max-width: 668px) {
  .mvv__theme {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}

.mvv__text {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
}

@media (max-width: 668px) {
  .mvv__text {
    margin-top: 20px;
    font-size: 20px;
    line-height: 35px;
  }
}

.service__content {
  padding: 0 7.6%;
}

@media (max-width: 1089px) {
  .service__content {
    padding: 0 20px;
  }
}

@media (max-width: 668px) {
  .service__content {
    padding: 0 7.2%;
  }
}

.servicelist {
  padding: 40px 0 0;
}

@media (max-width: 768px) {
  .servicelist {
    padding: 40px 0;
  }
}

@media (max-width: 668px) {
  .servicelist {
    display: block;
    padding: 20px 0 0;
  }
}

.servicelist__block {
  width: 50%;
  min-height: 365px;
  box-shadow: 0 0 10px 0 rgba(0, 91, 95, 0.15);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 24px 40px 40px;
}

@media (max-width: 1350px) {
  .servicelist__block {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .servicelist__block {
    height: auto;
  }
}

@media (max-width: 668px) {
  .servicelist__block {
    width: 100%;
    min-height: auto;
    padding: 16px 16px 20px;
  }
}

.servicelist__block:first-child {
  margin: 0 1.5% 0 0;
}

@media (max-width: 668px) {
  .servicelist__block:first-child {
    margin: 0 auto 20px;
  }
}

.servicelist__title {
  position: relative;
  color: #ED406F;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #888888;
  padding: 0 0 14px;
}

@media (max-width: 668px) {
  .servicelist__title {
    font-size: 1.38462rem;
    padding: 0 0 10px;
  }
}

@media (max-width: 320px) {
  .servicelist__title {
    font-size: 1.23077rem;
  }
}

.servicelist__title:before, .servicelist__title:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  left: 50%;
  border-style: solid;
  display: block;
  transform: translateX(-50%);
}

.servicelist__title:before {
  bottom: -11px;
  border-width: 12px 11.5px 0 11.5px;
  border-color: #ffffff transparent transparent transparent;
  z-index: 1;
}

@media (max-width: 668px) {
  .servicelist__title:before {
    bottom: -8px;
    border-width: 9px 8.5px 0 8.5px;
  }
}

.servicelist__title:after {
  bottom: -13px;
  border-width: 13px 12px 0 12px;
  border-color: #888888 transparent transparent transparent;
  z-index: 0;
}

@media (max-width: 668px) {
  .servicelist__title:after {
    bottom: -10px;
    border-width: 10px 9px 0 9px;
  }
}

.servicelist__content {
  font-size: 1.125rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0 0;
}

@media (max-width: 963px) {
  .servicelist__content {
    font-size: 0.875rem;
  }
}

@media (max-width: 668px) {
  .servicelist__content {
    font-size: 1.23077rem;
    display: block;
    padding: 20px 0 0;
  }
}

@media (max-width: 320px) {
  .servicelist__content {
    font-size: 1rem;
  }
}

.servicelist__content:first-child {
  justify-content: space-between;
}

.servicelist__content__block--02 {
  margin: 0 0 0 9%;
}

@media (max-width: 1412px) {
  .servicelist__content__block--02 {
    margin: 0 0 0 4%;
  }
}

@media (max-width: 668px) {
  .servicelist__content__block--02 {
    margin: 14px 0 0;
  }
}

.servicelist__content__item {
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 24px;
}

@media (max-width: 668px) {
  .servicelist__content__item {
    padding: 0 5px 14px;
  }
}

.servicelist__content__item:last-child {
  padding: 0;
}

@media (max-width: 668px) {
  .servicelist__content__item:last-child {
    padding: 0 5px;
  }
}

.servicelist__content__item::before {
  content: '';
  width: 6px;
  height: 6px;
  display: block;
  background-color: #cccccc;
  margin: 0 10px 0 0;
  flex-basis: 6px;
  flex-shrink: 0;
}

.profile__content {
  width: 100%;
  background-color: #ffffff;
  margin: 32px auto 0;
  padding: 60px;
}

@media (max-width: 668px) {
  .profile__content {
    margin: 20px auto 0;
    padding: 20px 16px 24px;
  }
}

.profile__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(237, 64, 111, 0.3);
  margin: 0 0 32px;
}

@media (max-width: 668px) {
  .profile__item {
    margin: 0 0 10px;
  }
}

.profile__item:last-child {
  margin: 0;
}

.profile__item:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -1px;
  width: 22.72%;
  height: 1px;
  display: block;
  background-color: #ED406F;
}

@media (max-width: 668px) {
  .profile__item:after {
    width: 38.27%;
  }
}

.profile__item:nth-child(1) br, .profile__item:nth-child(3) br, .profile__item:last-child br {
  display: none;
}

@media (max-width: 668px) {
  .profile__item:nth-child(1) br, .profile__item:nth-child(3) br, .profile__item:last-child br {
    display: block;
  }
}

@media (max-width: 668px) {
  .profile__item .name {
    display: block;
  }
}

@media (max-width: 668px) {
  .profile__item .member {
    margin-bottom: 10px;
  }
}

.profile__title, .profile__desc {
  padding: 0 0 16px 40px;
}

@media (max-width: 971px) {
  .profile__title, .profile__desc {
    padding: 0 0 16px 20px;
  }
}

@media (max-width: 805px) {
  .profile__title, .profile__desc {
    padding: 0 0 16px 10px;
  }
}

@media (max-width: 668px) {
  .profile__title, .profile__desc {
    padding: 0 10px 10px;
  }
}

@media (max-width: 360px) {
  .profile__title, .profile__desc {
    padding: 0 5px 10px;
  }
}

.profile__title {
  font-size: 1.25rem;
  font-weight: bold;
  width: 22.72%;
  display: flex;
  align-items: flex-start;
}

@media (max-width: 868px) {
  .profile__title {
    font-size: 1.125rem;
  }
}

@media (max-width: 668px) {
  .profile__title {
    font-size: 1.15385rem;
    width: 38.27%;
  }
}

@media (max-width: 320px) {
  .profile__title {
    font-size: 0.92308rem;
  }
}

.profile__desc {
  font-size: 1.125rem;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.75;
  width: 77.27%;
}

@media (max-width: 668px) {
  .profile__desc {
    font-size: 1.07692rem;
    width: 61.72%;
  }
}

@media (max-width: 320px) {
  .profile__desc {
    font-size: 0.92308rem;
  }
}

@media (max-width: 668px) {
  .access {
    display: block;
  }
}

.access .section__desc {
  text-align: center;
  padding: 24px 0 40px;
}

@media (max-width: 668px) {
  .access .section__desc {
    text-align: left;
    padding: 20px 0;
  }
}

@media (max-width: 668px) {
  .access__block {
    width: 100%;
  }
}

.access__block:nth-child(1) {
  margin: 0 0 0 2%;
}

@media (max-width: 668px) {
  .access__block:nth-child(1) {
    margin: 0 auto 20px;
  }
}

.access__map {
  width: 100%;
  height: 360px;
}

@media (max-width: 668px) {
  .access__map {
    height: 300px;
  }
}

.access__route {
  text-align: right;
}

@media (max-width: 668px) {
  .access__route {
    text-align: left;
  }
}

.access__route__block {
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding: 0 0 32px;
}

@media (max-width: 668px) {
  .access__route__block {
    text-align: left;
    padding: 0 0 20px;
  }
}

.access__route__block:last-child {
  padding: 0;
}

.access__route__title {
  color: #ED406F;
  font-weight: bold;
  padding: 0 0 16px;
}

@media (max-width: 668px) {
  .access__route__title {
    font-size: 1.23077rem;
    padding: 0;
  }
}

@media (max-width: 375px) {
  .access__route__title {
    font-size: 1.15385rem;
  }
}

@media (max-width: 320px) {
  .access__route__title {
    font-size: 1.07692rem;
  }
}

.access__route__time {
  color: #ED406F;
  padding: 0 0 10px 10px;
}

@media (max-width: 668px) {
  .access__route__time {
    font-size: 1.23077rem;
    padding: 10px 0;
  }
}

@media (max-width: 375px) {
  .access__route__time {
    font-size: 1.15385rem;
  }
}

@media (max-width: 360px) {
  .access__route__time {
    font-size: 1.07692rem;
  }
}

.access__route__desc {
  line-height: 1.5;
}

@media (max-width: 668px) {
  .access__route__desc {
    font-size: 1.23077rem;
  }
}

@media (max-width: 360px) {
  .access__route__desc {
    font-size: 1.15385rem;
  }
}

@media (max-width: 320px) {
  .access__route__desc {
    font-size: 1.07692rem;
  }
}

.access__route--02 {
  padding: 16px 0 0;
}

.contact__wrapper {
  background-color: #ffffff;
  margin: 40px auto 160px;
  padding: 40px;
}

@media (max-width: 969px) {
  .contact__wrapper {
    padding: 40px 10px;
  }
}

@media (max-width: 668px) {
  .contact__wrapper {
    display: block;
    margin: 20px auto 40px;
    padding: 32px 24px;
  }
}

.contact__block {
  text-align: center;
  width: 50%;
  padding: 24px;
}

@media (max-width: 969px) {
  .contact__block {
    padding: 24px 10px;
  }
}

@media (max-width: 833px) {
  .contact__block {
    padding: 24px 0;
  }
}

@media (max-width: 668px) {
  .contact__block {
    width: 100%;
    padding: 0 0 32px;
  }
}

.contact__block:nth-child(2) {
  border-left: 1px solid #cccccc;
}

@media (max-width: 668px) {
  .contact__block:nth-child(2) {
    border-left: none;
    border-top: 1px solid #cccccc;
    padding: 32px 0 0;
  }
}

.contact__block__desc {
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.5;
  padding: 0 0 24px;
}

@media (max-width: 668px) {
  .contact__block__desc {
    font-size: 1.23077rem;
    padding: 0 0 16px;
  }
}

@media (max-width: 320px) {
  .contact__block__desc {
    font-size: 1.07692rem;
  }
}
