.philosophy{
    &list{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 66px 7.6% 0;
        @include mq(md, max){
            padding: 66px 4% 0;
        }
        @include mq(sm, max){
            display: block;
            padding: 20px 7.2% 0;
        }
        &__block{
            max-width: 380px;
            @include mq(sm, max){
                max-width: 100%;
                margin: 0 auto 16px;
            }
            &:nth-child(2){
                margin: 0 2.8%;
                @include mq(sm, max){
                    margin: 0 auto 16px;
                }
            }
            &:last-child{
                @include mq(sm, max){
                    margin: 0 auto;
                }
            }
        }
        &__thumb{
            img{
                width: 100%;
            }
        }
        &__num{
            position: relative;
            color: $color-main;
            font-size: _rem(60px,md);
            font-style: italic;
            margin: 0 0 -20px;
            z-index: 1;
            @include mq(sm, max){
                font-size: _rem(40px,sm);
                margin: 0 0 -10px;
            }
        }
        &__title{
            font-size: _rem(20px,md);
            line-height: 1.5;
            text-align: center;
            padding: 20px 0 0;
            @include mq(sm, max){
                font-size: _rem(18px,sm);
                padding: 10px 0 0;
            }
            @include mq(320px, max){
                font-size: _rem(16px,sm);
            }
        }
    }
}