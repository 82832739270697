.hero{
    position: relative;
    width: 100%;
    @include mq(sm, max){
        height: calc(100vh - 50px);
        margin: 25px auto 0;
        overflow: hidden;
    }
    &__logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
        @include mq(sm, max){
            width: 280px;
            top: 45%;
        }
    }
}
@mixin sliderimg($num){
    width: 100% !important;
    height: 100vh !important;
    background-size: cover;
    @include mq(sm, max){
        height: calc(100vh - 50px) !important;
        background-image: url($dir+'/top/hero_0'+$num+'_sp.jpg');
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 100% !important;
        overflow: hidden;
    }
}
.slider{
    &__slide--01{
        @include sliderimg(1)
    }
    &__slide--02{
        @include sliderimg(2)
    }
    &__slide--03{
        @include sliderimg(3)
    }
}

.icon{
    &--print{
        position: fixed;
        color: $color-white;
        height: 118px;
        top: 50%;
        right: 0;
        display: block;
        background-color: $color-secondary;
        padding: 20px 16px;
        z-index: 10;
        transition: $duration;
        span{
            letter-spacing: 1.06px;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            display: block;
            margin: 10px auto 0;
        }
        &:hover{
            background-color: $color-main;
        }
        @include mq(sm, max){
            display: none;
        }
    }
}