.access{
    // display: flex;
    // align-items: flex-end;
    // justify-content: center;
    // flex-direction: row-reverse;
    @include mq(sm, max){
        display: block;
    }
    .section__desc{
        text-align: center;
        padding: 24px 0 40px;
        @include mq(sm, max){
            text-align: left;
            padding: 20px 0;
        }
    }
    &__block{
        // width: 50%;
        @include mq(sm, max){
            width: 100%;
        }
        &:nth-child(1){
            margin: 0 0 0 2%;
            @include mq(sm, max){
                margin: 0 auto 20px;
            }
        }
    }
    &__map{
        width: 100%;
        height: 360px;
        @include mq(sm, max){
            height: 300px;
        }
    }
    &__route{
        text-align: right;
        @include mq(sm, max){
            text-align: left;
        }
        &__block{
            font-family: $font-sanserif;
            padding: 0 0 32px;
            @include mq(sm, max){
                text-align: left;
                padding: 0 0 20px;
            }
            &:last-child{
                padding: 0;
            }
        }
        &__title{
            color: $color-main;
            font-weight: bold;
            padding: 0 0 16px;
            @include mq(sm, max){
                font-size: _rem(16px,sm);
                padding: 0;
            }
            @include mq(375px, max){
                font-size: _rem(15px,sm);
            }
            @include mq(320px, max){
                font-size: _rem(14px,sm);
            }
        }
        &__time{
            color: $color-main;
            padding: 0 0 10px 10px;
            @include mq(sm, max){
                font-size: _rem(16px,sm);
                padding: 10px 0;
            }
            @include mq(375px, max){
                font-size: _rem(15px,sm);
            }
            @include mq(360px, max){
                font-size: _rem(14px,sm);
            }
        }
        &__desc{
            line-height: 1.5;
            @include mq(sm, max){
                font-size: _rem(16px,sm);
            }
            @include mq(360px, max){
                font-size: _rem(15px,sm);
            }
            @include mq(320px, max){
                font-size: _rem(14px,sm);
            }
        }
        &--02{
            padding: 16px 0 0;
        }
    }
}