.profile{
    &__content{
        width: 100%;
        background-color: $color-white;
        margin: 32px auto 0;
        padding: 60px;
        @include mq(sm, max){
            margin: 20px auto 0;
            padding: 20px 16px 24px;
        }
    }
    &__item{
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid $color-lighter;
        margin: 0 0 32px;
        @include mq(sm, max){
            margin: 0 0 10px;
        }
        &:last-child{
            margin: 0;
        }
        &:after{
            position: absolute;
            content: '';
            left: 0;
            bottom: -1px;
            width: 22.72%;
            height: 1px;
            display: block;
            background-color: $color-main;
            @include mq(sm, max){
                width: 38.27%;
            }
        }
        &:nth-child(1),
        &:nth-child(3),
        &:last-child{
            br{
                display: none;
                @include mq(sm, max){
                    display: block;
                }
            }
        }
        .name{
            @include mq(sm, max){
                display: block;
            }
        }
        .member{
            @include mq(sm, max){
                margin-bottom: 10px;
            }
        }
        // &:nth-child(1){
        //     span{
        //         @include mq(sm, max){
        //             font-size: _rem(13px,sm);
        //             letter-spacing: -.1px;
        //         }
        //         @include mq(320px, max){
        //             font-size: _rem(12px,sm);
        //         }
        //     }
        // }
        // &:nth-child(5),
        // &:nth-child(6){
        //     span{
        //         @include mq(sm, max){
        //             display: inline-block;
        //             padding: 0 0 8px;
        //             &:last-child{
        //                 padding: 0;
        //             }
        //         }
        //     }
        // }
    }
    &__title,
    &__desc{
        padding: 0 0 16px 40px;
        @include mq(971px, max){
            padding: 0 0 16px 20px;
        }
        @include mq(805px, max){
            padding: 0 0 16px 10px;
        }
        @include mq(sm, max){
            padding: 0 10px 10px;
        }
        @include mq(360px, max){
            padding: 0 5px 10px;
        }
    }
    &__title{
        font-size: _rem(20px,md);
        font-weight: bold;
        width: 22.72%;
        display: flex;
        align-items: flex-start;
        @include mq(868px, max){
            font-size: _rem(18px,md);
        }
        @include mq(sm, max){
            font-size: _rem(15px,sm);
            width: 38.27%;
        }
        @include mq(320px, max){
            font-size: _rem(12px,sm);
        }
    }
    &__desc{
        font-size: _rem(18px,md);
        font-family: $font-sanserif;
        line-height: 1.75;
        width: 77.27%;
        @include mq(sm, max){
            font-size: _rem(14px,sm);
            width: 61.72%;
        }
        @include mq(320px, max){
            font-size: _rem(12px,sm);
        }
    }
}